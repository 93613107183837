import { EditOutlined, LinkOutlined } from '@ant-design/icons'
import { type QueryResult, useMutation } from '@apollo/client'
import {
  DeleteOrganizationDocument,
  type Exact,
  type FetchAdminOrganizationsQuery,
  type OrganizationFieldsFragment,
  OrganizationStatusEnum,
  SourceProviderEnum,
  type SubscriptionFieldsFragment,
  UpdateOrganizationDocument,
} from '@salescore/admin-client-typed-document-node'
import { Avatar, Button, message, Select, Space, Table } from 'antd'
import Modal from 'antd/es/modal/Modal'
import dayjs from 'dayjs'
import type { Maybe } from 'graphql/jsutils/Maybe'
import { t } from 'i18next'
import Link from 'next/link'
import { useState } from 'react'

import { compareFunction } from '../../../common/util'
import { createDestroyColumn, getColumnSearchProps as getColumnSearchProperties } from '../../common/antd'
import { handleQuery } from '../../common/handleQuery'
import { useModal } from '../../hooks/useModal'
import { ORGANIZATION_STATUS_OPTIONS, OrganizationStatusTag } from '../shared/OrganizationStatusTag'
import { ProviderLogo } from '../shared/ProviderLogo'
import { SubscriptionPlanTag } from '../shared/SubscriptionPlanTag'
import { SubscriptionForms } from './SubscriptionForm'

export const OrganizationsTable = ({
  fetchAdminOrganizationsQuery,
}: {
  fetchAdminOrganizationsQuery: QueryResult<
    FetchAdminOrganizationsQuery,
    Exact<Record<string, never> | { shouldFetchDeleted?: Maybe<boolean> }>
  >
}): JSX.Element => {
  const [updateOrganizationMutation] = useMutation(UpdateOrganizationDocument)
  const [deleteOrganizationMutation] = useMutation(DeleteOrganizationDocument)
  const [loadingOrganizationIds, setLoadingOrganizationIds] = useState<string[]>([])
  const [destroyLoading, setDestroyLoading] = useState(false)
  const subscriptionFormModal = useModal<{ organizationId: string; subscriptions: SubscriptionFieldsFragment[] }>()

  const onDestroy = (record: OrganizationFieldsFragment): void => {
    void deleteOrganizationMutation({
      variables: {
        organizationId: record.id,
      },
      onCompleted: () => {
        void fetchAdminOrganizationsQuery.refetch()
      },
    })
  }

  return handleQuery(
    fetchAdminOrganizationsQuery,
    (data) => {
      return (
        <>
          <Table
            rowKey="id"
            dataSource={data.adminOrganizations}
            pagination={{
              pageSize: 100,
            }}
            columns={[
              {
                title: '',
                dataIndex: 'imageUrl',
                render(_, record) {
                  return <Avatar src={record.imageUrl} shape="square" />
                },
              },
              {
                title: '組織名',
                dataIndex: 'name',
                sorter: (a, b) => {
                  return compareFunction(a.name, b.name)
                },
                ...getColumnSearchProperties((record: OrganizationFieldsFragment) => record.name),
              },
              {
                title: '',
                dataIndex: 'status',
                sorter: (a, b) => {
                  return compareFunction(a.status, b.status)
                },
                filters: ORGANIZATION_STATUS_OPTIONS.map((x) => ({ value: x.value, text: x.label })),
                onFilter: (value, record) => record.status.includes(value as string),
                render(_, record) {
                  return (
                    <Select
                      bordered={false}
                      loading={loadingOrganizationIds.includes(record.id)}
                      value={record.status}
                      onChange={(status: OrganizationStatusEnum) => {
                        setLoadingOrganizationIds((ids) => [...ids, record.id])
                        void updateOrganizationMutation({
                          variables: {
                            organizationId: record.id,
                            organization: {
                              name: record.name,
                              adminMemo: record.adminMemo,
                              status,
                            },
                          },
                          onCompleted: () => {
                            void message.success(t(`更新しました`))
                            setLoadingOrganizationIds((ids) => ids.filter((x) => x !== record.id))
                          },
                        })
                      }}
                    >
                      {ORGANIZATION_STATUS_OPTIONS.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          <OrganizationStatusTag status={option.value} />
                        </Select.Option>
                      ))}
                    </Select>
                  )
                },
              },
              {
                title: 'プラン',
                dataIndex: 'subscriptions',
                sorter: (a, b) => {
                  return compareFunction(
                    a.subscriptions.map((x) => x.plan).join(','),
                    b.subscriptions.map((x) => x.plan).join(','),
                  )
                },
                render(_value, record) {
                  return (
                    <div
                      onClick={() => {
                        subscriptionFormModal.showModal({
                          organizationId: record.id,
                          subscriptions: record.subscriptions,
                        })
                      }}
                      className="cursor-pointer"
                    >
                      <Space>
                        {record.subscriptions.map((subscription, index) => (
                          <SubscriptionPlanTag
                            key={index}
                            plan={subscription.plan}
                            numberOfSeats={subscription.numberOfSeats}
                          />
                        ))}
                        {record.subscriptions.isBlank() && <span>なし</span>}
                        <EditOutlined />
                      </Space>
                    </div>
                  )
                },
              },
              {
                title: '作成日',
                dataIndex: 'createdAt',
                sorter: (a, b) => {
                  return compareFunction(dayjs(a.createdAt).unix(), dayjs(b.createdAt).unix())
                },
              },
              {
                title: '合計ライセンス数',
                key: 'subscription',
                sorter: (a, b) => {
                  return compareFunction(
                    a.subscriptions.map((x) => x.numberOfSeats).sum(),
                    b.subscriptions.map((x) => x.numberOfSeats).sum(),
                  )
                },
                render(_, record) {
                  return <Space>{record.subscriptions.map((x) => x.numberOfSeats).sum()}</Space>
                },
              },
              {
                title: '連携先',
                key: 'sources',
                filters: Object.values(SourceProviderEnum).map((x) => ({ value: x, text: x })),
                // フィルタ結果の処理
                onFilter: (value, record) =>
                  record.sources
                    .map((x) => x.provider)
                    .join(',')
                    .includes(value as string),
                sorter: (a, b) => {
                  return compareFunction(
                    a.sources.map((x) => x.provider).join(','),
                    b.sources.map((x) => x.provider).join(','),
                  )
                },
                render(_, record) {
                  return (
                    <Space>
                      {record.sources
                        .filter((x) => x.provider !== SourceProviderEnum.Salescore)
                        .map((source) => (
                          <ProviderLogo
                            key={`${record.id}-${source.id}`}
                            provider={source.provider}
                            active={source.connection?.active === true}
                          />
                        ))}
                    </Space>
                  )
                },
              },
              {
                title: '',
                key: 'externalLink',
                render(_, record) {
                  return (
                    <a
                      href={`${process.env.FRONT_MAIN_URL ?? 'https://app.salescore.jp'}/o/${record.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button icon={<LinkOutlined />}>本番</Button>
                    </a>
                  )
                },
              },
              {
                title: '',
                key: 'link',
                render(_, record) {
                  return (
                    <Link href={`/o/${record.id}`} legacyBehavior>
                      <Button>詳細</Button>
                    </Link>
                  )
                },
              },
              createDestroyColumn(
                destroyLoading,
                setDestroyLoading,
                onDestroy,
                (organization) => organization.status !== OrganizationStatusEnum.Suspended,
              ),
            ]}
          />
          <Modal
            visible={subscriptionFormModal.isModalVisible}
            onCancel={subscriptionFormModal.hideModal}
            width={'60%'}
            cancelText={'閉じる'}
            okButtonProps={{ style: { display: 'none' } }}
            title={<div>ライセンス数編集</div>}
            style={{ top: '3%' }}
            destroyOnClose
          >
            {subscriptionFormModal.content !== undefined && (
              <SubscriptionForms
                organizationId={subscriptionFormModal.content.organizationId}
                subscriptions={subscriptionFormModal.content.subscriptions}
                onAfterFinish={() => {
                  void fetchAdminOrganizationsQuery.refetch()
                  subscriptionFormModal.hideModal()
                }}
              />
            )}
          </Modal>
        </>
      )
    },
    <Table loading={true} />,
  )
}
