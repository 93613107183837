import type { ApolloError } from '@apollo/client'
import { logger, useRedirect } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'

interface QueryResult<T> {
  data?: T
  error?: ApolloError
  loading: boolean
}

export function handleQuery<T>(
  query: QueryResult<T>,
  callback: (data: T) => JSX.Element,
  loadingElement?: undefined | JSX.Element,
  _option?: {
    onError?: (error: unknown) => JSX.Element
  },
) {
  const redirect = useRedirect()

  // admin権限がなくてクエリーが拒否されたときにはログアウトさせる
  if (query.error !== undefined && query.error.graphQLErrors[0]?.extensions.code === 'FORBIDDEN') {
    redirect('/sign_out')
    return <div></div>
  }

  if (query.error !== undefined) {
    void message.error(t(`エラーが発生しました`))

    logger.debug(query.error)
    return <div></div>
  }

  if (query.data === undefined) {
    return loadingElement ?? <div></div>
  }
  if (query.loading && loadingElement) {
    return loadingElement
  }

  return callback(query.data)
}
