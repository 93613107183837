type Ordering = -1 | 0 | 1
const toOrdering = (x: number): Ordering => {
  if (x < 0) {
    return -1
  }
  if (x > 0) {
    return 1
  }
  return 0
}

const orderNullable = (a: unknown, b: unknown) => {
  const rankA = a === null ? 1 : a === undefined ? 2 : 0
  const rankB = b === null ? 1 : b === undefined ? 2 : 0
  return toOrdering(rankA - rankB)
}
export const compareFunction = (a: unknown, b: unknown): Ordering => {
  if (typeof a === 'number' && typeof b === 'number') {
    return toOrdering(a - b)
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return toOrdering(a.localeCompare(b))
  }
  if (a === undefined || b === undefined || a === null || b === null) {
    return orderNullable(a, b)
  }

  return toOrdering(JSON.stringify(a ?? null).localeCompare(JSON.stringify(b ?? null)))
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const parseJsonIfValid = (x: string): unknown | undefined => {
  try {
    return JSON.parse(x)
  } catch {
    return undefined
  }
}
