import { type OrganizationFieldsFragment, useRestoreDeletedOrganizationMutation } from '@salescore/admin-client-api'
import { Button, message, Popconfirm } from 'antd'
import { t } from 'i18next'

export const RestoreDeletedOrganizationButton = ({
  organization,
  onFinish,
}: {
  organization: OrganizationFieldsFragment
  onFinish: () => void
}) => {
  const [restoreDeletedOrganizationMutation] = useRestoreDeletedOrganizationMutation()

  return (
    <Popconfirm
      title="本当に復旧しますか？"
      onConfirm={async () => {
        try {
          await restoreDeletedOrganizationMutation({
            variables: {
              organizationId: organization.id,
            },
          })
          onFinish()
          void message.success(t(`復旧しました`))
        } catch {
          void message.error(t(`エラーが発生しました`))
        }
      }}
    >
      <Button type="primary" danger>
        復旧
      </Button>
    </Popconfirm>
  )
}
