import { SubscriptionPlanEnum } from '@salescore/admin-client-api'
import { Tag } from 'antd'

export const SUBSCRIPTION_STATUS_OPTIONS = [
  {
    label: 'VISUALIZE',
    value: SubscriptionPlanEnum.Visualize,
  },
  {
    label: 'SYNC',
    value: SubscriptionPlanEnum.Sync,
  },
]

export const SUBSCRIPTION_STATUS_COLORS = {
  [SubscriptionPlanEnum.Visualize]: 'green',
  [SubscriptionPlanEnum.Sync]: 'blue',
}

export const SUBSCRIPTION_STATUS_JA = {
  [SubscriptionPlanEnum.Visualize]: 'VISUALIZE',
  [SubscriptionPlanEnum.Sync]: 'SYNC',
}

export const SubscriptionPlanTag = ({ plan, numberOfSeats }: { plan: SubscriptionPlanEnum; numberOfSeats: number }) => {
  if (plan !== 'visualize' && plan !== 'sync') {
    return <></>
  }

  return (
    <Tag color={SUBSCRIPTION_STATUS_COLORS[plan]}>
      {SUBSCRIPTION_STATUS_JA[plan]}({numberOfSeats})
    </Tag>
  )
}
