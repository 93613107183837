import { SaveOutlined } from '@ant-design/icons'
import {
  type SubscriptionFieldsFragment,
  SubscriptionPlanEnum,
  useUpdateSubscriptionMutation,
} from '@salescore/admin-client-api'
import { App, Button, Divider, Form, InputNumber } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { t } from 'i18next'

import { SUBSCRIPTION_STATUS_OPTIONS } from '../shared/SubscriptionPlanTag'

interface FormValue {
  numberOfSeats: number
  pricePerSeat: number
  plan: SubscriptionPlanEnum
  note?: string
}

export const SubscriptionForms = ({
  organizationId,
  subscriptions,
  onAfterFinish,
}: {
  organizationId: string
  subscriptions: SubscriptionFieldsFragment[]
  onAfterFinish: () => void
}): JSX.Element => {
  const plans: SubscriptionPlanEnum[] = [SubscriptionPlanEnum.Visualize, SubscriptionPlanEnum.Sync]

  return (
    <div>
      {plans.map((plan, index) => {
        return (
          <SubscriptionForm
            key={index}
            organizationId={organizationId}
            plan={plan}
            subscription={subscriptions.find((x) => x.plan === plan)}
            onAfterFinish={onAfterFinish}
          />
        )
      })}
    </div>
  )
}

function SubscriptionForm({
  organizationId,
  plan,
  subscription,
  onAfterFinish,
}: {
  organizationId: string
  plan: SubscriptionPlanEnum
  subscription?: SubscriptionFieldsFragment
  onAfterFinish: () => void
}): JSX.Element {
  const { message } = App.useApp()
  const [updateSubscriptionMutation, { loading }] = useUpdateSubscriptionMutation()

  const onFinish = (value: FormValue): void => {
    void updateSubscriptionMutation({
      variables: {
        organizationId,
        subscription: {
          numberOfSeats: value.numberOfSeats,
          pricePerSeat: value.pricePerSeat ?? 5000,
          plan,
          note: value.note,
        },
      },
      onCompleted: () => {
        onAfterFinish()
        message.success(t(`更新しました`))
      },
    })
  }

  return (
    <div>
      <div className="mb-3 text-xl font-bold">{SUBSCRIPTION_STATUS_OPTIONS.find((x) => x.value === plan)?.label}</div>
      <Form initialValues={subscription} onFinish={onFinish}>
        <Form.Item
          name="numberOfSeats"
          label="ライセンス数"
          rules={[{ required: true, message: 'ライセンス数を入力してください' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="備考(あれば)" name="note">
          <TextArea />
        </Form.Item>
        <Form.Item>
          <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading}>
            保存
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </div>
  )
}
