import type { QueryResult } from '@apollo/client'
import {
  type Exact,
  type FetchAdminOrganizationsQuery,
  type Maybe,
  type OrganizationFieldsFragment,
  SourceProviderEnum,
} from '@salescore/admin-client-api'
import { Avatar, Space, Table } from 'antd'
import dayjs from 'dayjs'

import { compareFunction } from '../../../common/util'
import { getColumnSearchProps as getColumnSearchProperties } from '../../common/antd'
import { handleQuery } from '../../common/handleQuery'
import { OrganizationStatusTag } from '../shared/OrganizationStatusTag'
import { ProviderLogo } from '../shared/ProviderLogo'
import { SubscriptionPlanTag } from '../shared/SubscriptionPlanTag'
import { RestoreDeletedOrganizationButton } from './RestoreDeletedOrganizationButton'

export const DeletedOrganizationsTable = ({
  fetchDeletedOrganizationsQuery,
}: {
  fetchDeletedOrganizationsQuery: QueryResult<
    FetchAdminOrganizationsQuery,
    Exact<Record<string, never> | { shouldFetchDeleted?: Maybe<boolean> }>
  >
}) => {
  return handleQuery(
    fetchDeletedOrganizationsQuery,
    (data) => {
      return (
        <Table
          rowKey="id"
          dataSource={data.adminOrganizations}
          pagination={{
            pageSize: 100,
          }}
          columns={[
            {
              title: '',
              dataIndex: 'imageUrl',
              render(_, record) {
                return <Avatar src={record.imageUrl} shape="square" />
              },
            },
            {
              title: '組織名',
              dataIndex: 'name',
              sorter: (a, b) => {
                return compareFunction(a.name, b.name)
              },
              ...getColumnSearchProperties((record: OrganizationFieldsFragment) => record.name),
            },
            {
              title: '',
              dataIndex: 'status',
              render(_, record) {
                return <OrganizationStatusTag status={record.status} />
              },
            },
            {
              title: 'プラン',
              dataIndex: 'subscriptions',
              sorter: (a, b) => {
                return compareFunction(
                  a.subscriptions.map((x) => x.plan).join(','),
                  b.subscriptions.map((x) => x.plan).join(','),
                )
              },
              render(_value, record) {
                return (
                  <Space>
                    {record.subscriptions.map((subscription) => (
                      <SubscriptionPlanTag plan={subscription.plan} numberOfSeats={subscription.numberOfSeats} />
                    ))}
                  </Space>
                )
              },
            },
            {
              title: '作成日',
              dataIndex: 'createdAt',
              sorter: (a, b) => {
                return compareFunction(dayjs(a.createdAt).unix(), dayjs(b.createdAt).unix())
              },
            },
            {
              title: '削除日',
              dataIndex: 'deletedAt',
              sorter: (a, b) => {
                return compareFunction(dayjs(a.deletedAt).unix(), dayjs(b.deletedAt).unix())
              },
            },
            {
              title: '連携先',
              key: 'sources',
              filters: Object.values(SourceProviderEnum).map((x) => ({ value: x, text: x })),
              // フィルタ結果の処理
              onFilter: (value, record) =>
                record.sources
                  .map((x) => x.provider)
                  .join(',')
                  .includes(value as string),
              sorter: (a, b) => {
                return compareFunction(
                  a.sources.map((x) => x.provider).join(','),
                  b.sources.map((x) => x.provider).join(','),
                )
              },
              render(_, record) {
                return (
                  <Space>
                    {record.sources
                      .filter((x) => x.provider !== SourceProviderEnum.Salescore)
                      .map((source) => (
                        <ProviderLogo
                          key={`${record.id}-${source.provider}`}
                          provider={source.provider}
                          active={source.connection?.active === true}
                        />
                      ))}
                  </Space>
                )
              },
            },
            {
              title: '',
              key: 'restore',
              render(_, record) {
                return (
                  <RestoreDeletedOrganizationButton
                    organization={record}
                    onFinish={async () => {
                      await fetchDeletedOrganizationsQuery.refetch()
                    }}
                  />
                )
              },
            },
          ]}
        />
      )
    },
    <Table loading={true} />,
  )
}
