import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, message, Popconfirm, Space } from 'antd'
import type { FilterDropdownProps } from 'antd/es/table/interface'
import { t } from 'i18next'
import { type Dispatch, type SetStateAction, useRef } from 'react'

import { isSome } from '../../common/like-fp-ts'

export function createDestroyColumn<T>(
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
  onDestroy: (record: T) => void,
  condition?: (x: T) => boolean,
) {
  return {
    title: '',
    key: 'destroy',
    width: 20,
    render(_text: unknown, record: T) {
      const confirm = (): void => {
        try {
          setLoading(true)
          onDestroy(record)
          void message.success(t(`削除しました`))
        } catch (error) {
          // TODO
          if (error instanceof Error) {
            void message.error(`${t(`削除に失敗しました。`)}${error.message}`)
          } else {
            void message.error(t(`削除に失敗しました。`))
          }
          throw error
        } finally {
          setLoading(false)
        }
      }

      if (condition !== undefined && !condition(record)) {
        return <></>
      }

      return (
        <Popconfirm title="本当に削除しますか?" onConfirm={confirm} okText="はい" cancelText="いいえ">
          <Button type="text" loading={loading} icon={<DeleteOutlined />}></Button>
        </Popconfirm>
      )
    },
  }
}

export function getColumnSearchProps<T>(recordToValue: (record: T) => string) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      const reference = useRef(null)

      // 正しい方法ではなさそうだが、これ以外でfocusできなかった
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const current: any = reference.current
        if (isSome(current)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          current.focus()
        }
      }, 200)

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={reference}
            // placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(event) => {
              setSelectedKeys(event.target.value === undefined ? [] : [event.target.value])
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => {
              confirm({ closeDropdown: true })
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            {/* <Button
            type="primary"
            onClick={(e) => { confirm({ closeDropdown: true }) }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            検索
          </Button> */}
            <Button onClick={clearFilters}>リセット</Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFilter: (value: any, record: T) =>
      recordToValue(record)
        .toLowerCase()
        .includes(typeof value === 'string' ? value.toLowerCase() : (value as string)),
  }
}
