import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { FetchAdminOrganizationsDocument } from '@salescore/admin-client-typed-document-node'
import { Button, Card, Modal, Tabs } from 'antd'

import { CreateOrganizationForm } from '../presentation/components/organizations/CreateOrganizationForm'
import { DeletedOrganizationsTable } from '../presentation/components/organizations/DeletedOrganizationsTable'
import { OrganizationsTable } from '../presentation/components/organizations/OrganizationsTable'
import { useModal } from '../presentation/hooks/useModal'

const { TabPane } = Tabs

const Page = (): JSX.Element => {
  const organizationFormModal = useModal()
  const fetchAdminOrganizationsQuery = useQuery(FetchAdminOrganizationsDocument, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <Card
      className="m-5"
      title="組織一覧"
      extra={[
        <Button key="button-create" icon={<PlusOutlined />} type="primary" onClick={organizationFormModal.showModal}>
          新規作成
        </Button>,
      ]}
    >
      <Tabs
        defaultActiveKey="1"
        onChange={(activeKey) => {
          void (activeKey === '1'
            ? fetchAdminOrganizationsQuery.refetch({ shouldFetchDeleted: false })
            : fetchAdminOrganizationsQuery.refetch({ shouldFetchDeleted: true }))
        }}
      >
        <TabPane tab="一覧" key="1">
          <OrganizationsTable fetchAdminOrganizationsQuery={fetchAdminOrganizationsQuery} />
        </TabPane>
        <TabPane tab="削除済み一覧" key="2">
          <DeletedOrganizationsTable fetchDeletedOrganizationsQuery={fetchAdminOrganizationsQuery} />
        </TabPane>
      </Tabs>
      <Modal
        open={organizationFormModal.isModalVisible}
        onCancel={organizationFormModal.hideModal}
        width={'60%'}
        cancelText={'閉じる'}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div>新規組織作成</div>}
        style={{ top: '3%' }}
      >
        <CreateOrganizationForm
          onAfterFinish={() => {
            void fetchAdminOrganizationsQuery.refetch()
            organizationFormModal.hideModal()
          }}
        />
      </Modal>
    </Card>
  )
}

export default Page
