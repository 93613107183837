import { useState } from 'react'

// example
// const { isModalVisible, showModal, hideModal } = useModal()
//
// <Modal visible={isModalVisible} onCancel={hideModal} width={"80%"}>
//   <KpiModalContent record={record} dashboard={dashboard} kpi={kpi} nextStepKpi={nextStepKpi} period={period} />
// </Modal>
//

/**
 * @deprecated
 */
export const useModal = <T>() => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [content, setContent] = useState<T | undefined>()
  const showModal = (content?: T) => {
    setIsModalVisible(true)
    setContent(content)
  }
  const hideModal = () => {
    setIsModalVisible(false)
    setContent(undefined)
  }

  return {
    isModalVisible,
    content,
    showModal,
    hideModal,
  }
}
