export function isSome<T>(x: T): x is Exclude<T, undefined | null> {
  return x !== undefined && x !== null
}

export function isNull(x: unknown): x is undefined | null {
  return !isSome(x)
}

export function isPresent<T>(x: T): x is Exclude<T, undefined | null> {
  if (isNull(x)) {
    return false
  }
  if (typeof x === 'string') {
    return x !== ''
  }
  if (Array.isArray(x)) {
    return x.length > 0
  }

  return true
}

export function compact<T>(xs: T[]): Array<Exclude<T, undefined | null>> {
  return xs.filter((x) => isSome(x))
}

export const sum = function (xs: number[]) {
  return xs.reduce((accumulator, x) => accumulator + x, 0)
}
